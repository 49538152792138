import { Box } from '@quarkly/widgets';
import React, { createContext, useState } from 'react';
import { createStore } from 'zustand';
import { makeUseStateReplacer, makeUseStateSelector, makeUseStoreFromContext, useEffectQuarklySafe } from './Utils';
import { useNavigate } from './Navigate';
import { init, pageView, useAnalytics } from './Analytics';

const createPageStore = () => createStore((set, get) => {
	const useStateReplacer = makeUseStateReplacer(set);
	return { ...useStateReplacer('progress', 0)
	};
});

export const progressSelector = makeUseStateSelector('progress');
const AnalyzingTheAnswersPageContext = createContext(null);
export const useAnalyzingTheAnswersPageStore = makeUseStoreFromContext(AnalyzingTheAnswersPageContext);

const counterBalance = ({
	start,
	limit,
	interval,
	index,
	cb
}) => {
	let i = start;
	return new Promise((resolve, reject) => {
		const timerId = setInterval(() => {
			if (i >= limit) {
				clearInterval(timerId);
				resolve(index);
			} // } else {
			//     cb(i)
			// }


			cb(i);
			i += 1;
		}, interval);
	});
};

function AnalyzingTheAnswersPageWrapper({
	children,
	...props
}) {
	const setProgress = useAnalyzingTheAnswersPageStore(progressSelector.onlySet);
	const navigate = useNavigate();
	const analytics = useAnalytics();
	useEffectQuarklySafe(() => {
		async function run() {
			init();
			pageView();
			await counterBalance({
				start: 0,
				limit: 24,
				interval: 55,
				cb: setProgress
			});
			await counterBalance({
				start: 24,
				limit: 49,
				interval: 100,
				cb: setProgress
			});
			await counterBalance({
				start: 50,
				limit: 74,
				interval: 55,
				cb: setProgress
			});
			await counterBalance({
				start: 75,
				limit: 99,
				interval: 60,
				cb: setProgress
			});
			await counterBalance({
				start: 99,
				limit: 100,
				interval: 20,
				cb: setProgress
			});
			const title = document.querySelector('#question-title')?.textContent || '';
			await analytics({
				type: 'QuestionaryCompleted'
			});
			await analytics({
				type: 'OnboardingSurveyQuestionAnswered',
				question_id: '659',
				amplitude: '32_dr_coach_analyzing_the_answers',
				answer: 'passed',
				title,
				button: ''
			}); //Смотрим в localStorage и вытаскиваем оттуда параметры от Google Ads для последующей передачи в Braintree на пейволе

			const savedParamsJson = localStorage.getItem('saved_url_params');
			let additionalParams = '';

			if (savedParamsJson) {
				const savedParams = JSON.parse(savedParamsJson);

				for (const key in savedParams) {
					additionalParams += `&${key}=${savedParams[key]}`;
				}
			}

			setTimeout(function () {
				const currentUrl = window.location.href;
				let redirectUrl = ''; // Проверяем наличие подпути в текущем URL

				if (currentUrl.includes('/uap/')) {
					redirectUrl = 'https://join2.the.coach/plans-green.html?pw=uap_ggl_ww_health_discount&webview=true&link=uap_ggl';

					if (!additionalParams) {
						additionalParams = '&utm_source=UAP_ggl&utm_medium=undefined';
					}
				} else if (currentUrl.includes('/goats/')) {
					redirectUrl = 'https://join2.the.coach/plans-green.html?pw=gts_disc_cel&webview=true';

					if (!additionalParams) {
						additionalParams = '&utm_source=goats&utm_medium=undefined';
					}
				} else if (currentUrl.includes('/atlas/')) {
					redirectUrl = 'https://join2.the.coach/plans-green.html?pw=atls_disc_cel&webview=true';

					if (!additionalParams) {
						additionalParams = '&utm_source=atlas&utm_medium=undefined';
					}
				} else if (currentUrl.includes('/tf/')) {
					redirectUrl = 'https://get.the.coach/plans?pw=health_disc_cel&add=pdf3';

					if (!additionalParams) {
						additionalParams = '&utm_source=trofim&utm_medium=undefined';
					}
				} // Если нашли подходящий путь, перенаправляем


				if (redirectUrl) {
					window.location.replace(`${redirectUrl}${additionalParams}`);
				}
			}, 1000);
		}

		run();
	}, []);
	return <Box display="contents" {...props}>
		      
		{children}
		    
	</Box>;
}

const HOC = Component => function Wrapped(props) {
	const [store] = useState(() => createPageStore());
	return <AnalyzingTheAnswersPageContext.Provider value={store}>
		        
		<Component {...props} />
		      
	</AnalyzingTheAnswersPageContext.Provider>;
};

export default HOC(AnalyzingTheAnswersPageWrapper);